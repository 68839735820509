﻿/*
 *  Document   : dark.scss
 *  Author     : RedStar Template
 *  Description: This scss file for dark theme style classes
 */
.dark {
  background-color: #232b3e;
  color: #96a2b4;
  padding-top: 1px;

  body,
  html {
    background-color: #232b3e;
  }

  input {
    color: #96a2b4 !important;
  }

  .dark-font-col {
    color: #96a2b4;
  }

  .card {
    background: #1a202e;
    border: none;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);

    .body {
      color: #96a2b4;
    }

    .header {
      color: #96a2b4;

      h2 {
        color: #96a2b4;
      }
    }

    .card-content {
      color: #96a2b4;
    }
  }

  .course-card {
    background: #1a202e;

    .bg-body-light {
      background-color: #292c31;
    }
  }

  .banner-card {
    background: #1a202e;
  }

  .plain-card {
    background: #1a202e;
  }

  .people-list {
    .chat-list {
      li {
        &:hover {
          background: #3f4650;
          cursor: pointer;
        }

        &.active {
          background: #262b33;
        }
      }
    }
  }

  .chat {
    .chat-history {
      .message-data-time {
        color: #96a2b4;
      }
    }
  }

  .contact-usertitle-name {
    color: #fff;
  }

  .demo-skin {
    color: #eaeaea;

    .form-check-label {
      color: #eaeaea;
    }
  }

  .demo-settings {
    color: #eaeaea;
  }

  .block-header {
    padding-bottom: 0px;

    h2 {
      color: #96a2b4 !important;
    }
  }

  .select-wrapper {
    input.select-dropdown {
      color: #fff;
      font-size: 13px;
    }
  }

  .breadcrumb {
    li {
      &.active {
        color: #a5abb1;
      }
    }
  }

  .dropzone {
    border: 1px solid #afacac !important;
    background-color: #1a202e !important;

    .dz-message {
      min-height: 150px !important;
      background: #1a202e !important;
      color: white;
    }
  }

  .sl-item {
    .sl-content {
      p {
        color: #96a2b4;
      }
    }
  }

  .info-box5 {
    background-color: #1a202e;
  }

  .form-check {
    color: #96a2b4;
  }

  label {
    color: #96a2b4;
  }

  .input-field {

    input,
    textarea {
      color: #96a2b4;
    }
  }

  .input-group {
    .input-group-addon {
      .material-icons {
        color: #96a2b4;
      }
    }

    input[type="text"],
    .form-control {
      color: #96a2b4;
    }
  }

  .container-login100 {
    .form-group {
      .form-control {
        background: transparent;
      }
    }
  }

  .form-group {
    .form-control {
      color: #96a2b4;
      background: #1a202e;
    }

    input.form-control {
      color: #96a2b4;
      border-bottom: 1px solid #9e9e9e;
    }
  }

  .form-check {
    .form-check-sign {
      .check {
        border: 1px solid #96a2b4;
      }
    }
  }

  .form-control {
    color: #96a2b4;
    background: #1a202e;
  }

  .right-sidebar {
    background: #1a202e;
    box-shadow: 0 2px 5px rgba(206, 194, 194, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  }

  .ms-container {

    .ms-selectable,
    .ms-selection {
      li.ms-hover {
        color: #96a2b4 !important;
        background-color: #46484e !important;
      }
    }
  }

  .nav-tabs {
    li {
      a.active {
        color: #fff !important;
      }
    }
  }

  .bootstrap-tagsinput {
    background-color: #343840 !important;
  }

  .to-do-list {
    li {
      background: #1a202e;
    }
  }

  #mail-nav {
    li {
      a {
        color: #96a2b4;
      }
    }
  }

  .max-texts {
    a {
      color: #96a2b4;
    }
  }

  .pricingTable {
    background: #0c0c0c;

    .pricingTable-header {
      background: #36373c;
    }
  }

  .profile-tab-box {
    background: #1a202e;
  }

  .cd-timeline-content {
    background: #10131c;

    .timelineLabelColor strong {
      color: #ffffff !important;
    }
  }

  .cd-timeline-content h2 {
    color: #96a2b4;
  }

  .chart-note {
    color: #96a2b4;
  }

  .chart-statis {
    .index {
      color: #96a2b4;
    }
  }

  .dataTables_wrapper {
    input[type="search"] {
      color: #96a2b4;
    }
  }

  .page-item.disabled .page-link {
    color: #96a2b4;
    pointer-events: none;
    cursor: auto;
    background-color: #1a202e;
  }

  .table {
    tbody {
      tr {
        color: #96a2b4;

        td,
        th {
          border-top: 1px solid rgba(120, 130, 140, 0.5);
          border-bottom: 1px solid rgba(120, 130, 140, 0.5);
        }
      }
    }

    thead {
      tr {
        color: #96a2b4;

        th {
          border-bottom: 1px solid rgba(120, 130, 140, 0.5);
          border-top: 1px solid rgba(120, 130, 140, 0.5);
        }
      }
    }
  }

  .table-bordered {
    border: 1px solid rgba(120, 130, 140, 0.5);

    tbody {
      tr {

        td,
        th {
          border-right: 1px solid rgba(120, 130, 140, 0.5);
          border-bottom: none;
        }
      }
    }

    thead {
      tr {
        th {
          border: 1px solid rgba(120, 130, 140, 0.5);
        }
      }
    }

    th {
      border: 1px solid rgba(120, 130, 140, 0.5);
      color: #96a2b4;
    }
  }

  tr.group,
  tr.group:hover {
    background-color: #282d35 !important;
  }

  .tableBody {
    color: #96a2b4;
  }

  $theme-black: #1a202e;

  .card .header h2 strong {
    color: #96a2b4 !important;
  }

  .breadcrumb {
    .page-title {
      color: #a5abb1;
    }

    li a {
      color: #f1d065 !important;

      .fa-home {
        color: #a5abb1;
      }
    }
  }

  .counter-box,
  .box-part {
    background: #1a202e !important;
    color: #96a2b4;
  }

  .tblActnBtn i {
    color: #96a2b4;
  }

  .chat .chat-history {
    .other-message {
      background: #3b4453;

      &:after {
        border-bottom-color: #3b4454;
      }
    }

    .message {
      color: #96a2b4;
    }

    .my-message {
      background: #343840;

      &:after {
        border-bottom-color: #343840;
      }
    }
  }

  .task-box {
    border-bottom: solid 1px #5e5e5f;
    color: #bbb;
    background: #141a27;
  }

  .task-list {
    border: solid 1px #10141d;
    background: #141a27;
  }

  .mat-drawer {
    background-color: #141a27;
    color: #bbb;
    border-color: #121315 !important;
  }

  .mat-drawer-container {
    background-color: #1a202e;
  }

  .taskbar .card-footer {
    background: #1a202e !important;
  }

  .move {
    background: #1a202e;
  }

  .board {
    background-color: #353a46;

    .drop-card {
      background-color: #404754;
    }
  }

  .list-group-item {
    background: #1a202e;
  }

  .product-grid .product-content {
    background-color: #3b4453;
  }

  .ibox-title,
  .ibox-content {
    background: #1a202e;
    border-color: #6c757d;
  }

  .collapsible-header {
    background: #1a202e;
  }

  .card .card-inside-title {
    color: #96a2b4;
  }

  .left-timeline>li {
    &:nth-child(odd) {
      .left-label {
        background: #202529;

        &:after {
          border-right-color: #202428;
        }
      }

      .left-time span:last-child {
        color: #eee;
      }
    }

    .left-label {
      background: #303438;

      p {
        color: #96a2b4;
      }

      &:after {
        border-right-color: #303438;
      }
    }

    .empty span {
      color: #96a2b4;
    }

    .left-time span {
      &:first-child {
        color: #96a2b4;
      }

      &:last-child {
        color: #eae9e9;
      }
    }
  }

  .font-icon .icon-preview i {
    color: #96a2b4;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background-color: transparent;
  }

  .panel-group {
    .panel-primary .panel-title a {
      color: #96a2b4;
      background: #32363c;
    }

    .panel .panel-body {
      color: #96a2b4;
    }
  }

  .btn-outline-primary {
    color: #96a2b4 !important;
    border: 1px solid #96a2b4 !important;
  }

  .chip {
    background-color: #31353a;
    color: #96a2b4;
  }

  .dropdown-content {
    background-color: #31353a;

    li>span {
      color: #96a2b4;
    }
  }

  .select-dropdown li.disabled {
    color: #bbb;
    font-size: 13px;
  }

  .autocomplete {
    font-size: 13px !important;
    color: #bbb;
    caret-color: #fff;

    &:focus {
      color: #bbb;
    }
  }

  .autocomplete-content li {
    .highlight {
      color: #fff;
    }

    :hover {
      background-color: #343d44;
    }
  }

  //select 2
  .select2-container--default {
    .select2-selection--single {
      background: #1a202e;
      color: #bbb;
      border-bottom: 1px solid #9e9e9e;

      .select2-selection__rendered {
        background: #1a202e;
        color: #bbb;
        border-bottom: 1px solid #9e9e9e;
      }
    }

    .select2-selection--multiple {
      background: #1a202e;
      color: #bbb;
      border-bottom: 1px solid #9e9e9e;

      .select2-selection__choice {
        background-color: #444141;
        border: 1px solid #656464;
      }
    }

    &.select2-container--focus .select2-selection--multiple {
      background: #1a202e;
      color: #bbb;
      border-bottom: 1px solid #9e9e9e;
    }

    .select2-results>.select2-results__options {
      background-color: #282d35;
    }
  }

  .select2-search--dropdown {
    background-color: #1c1f25;
  }

  .flatpickr-input {
    border-bottom: 1px solid #9e9e9e !important;
  }

  .ngx-datatable.material {
    background: #1a202e;
    color: #96a2b4;

    .datatable-body .datatable-body-row .datatable-body-cell {
      color: #96a2b4;
    }

    .datatable-header-cell-label {
      color: #96a2b4;
    }

    :not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
      background-color: #1c1f25;
    }
  }

  .pagination>li>a:hover {
    background-color: #fff;
    color: #000;
  }

  .dataTables_length .custom-select {
    background-color: #1a202e;
    color: white;
  }

  .ngx-search {
    color: #bbb;
  }

  .ngx-datatable {
    &.material {
      background: #1a202e;
      border: 1px solid #404755;

      .datatable-header {
        .datatable-header-cell {
          background: #1a202e;
        }

        .resize-handle {
          border-right: solid 1px #1a202e;
        }
      }

      .datatable-footer {
        color: #fff;

        .datatable-pager {
          a {
            color: #fff;
          }

          li.disabled a {
            color: #fff !important;
          }
        }
      }

      .datatable-body .datatable-body-row .datatable-body-cell {
        color: #bbb;
      }

      &:not(.cell-selection) .datatable-body-row:hover {
        background: #32383e;

        .datatable-row-group {
          background: #32383e;
        }
      }

      .datatable-body-cell,
      .datatable-header-cell {
        border: 1px solid #404755;
      }
    }

    &.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
      color: #bbb;
    }
  }

  ::-webkit-input-placeholder {
    color: #96a2b4;
    opacity: 1 !important;
    /* for older chrome versions. may no longer apply. */
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #96a2b4;
    opacity: 1 !important;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #96a2b4;
    opacity: 1 !important;
  }

  :-ms-input-placeholder {
    color: #96a2b4;
  }

  input,
  textarea {
    color: #bbb;
  }

  .flatPicker {
    color: #bbb;
  }

  .modal {
    .modal-content {
      background: #1a202e;

      .modal-body {
        color: #bbb;
      }
    }

    .modal-close-button {
      color: #bbb;
    }
  }

  .ngx-search {
    color: #bbb;
  }

  .ngx-datatable {
    background: #434f5a;
    border: 1px solid #343d45;

    &.material {
      background: #1a202e;

      .datatable-header {
        .datatable-header-cell {
          background: #313742;
        }

        .resize-handle {
          border-right: solid 1px #434f5a;
        }
      }

      .datatable-footer {
        color: #fff;

        .datatable-pager {
          a {
            color: #fff;
          }

          li.disabled a {
            color: #fff !important;
          }
        }
      }

      .datatable-body .datatable-body-row .datatable-body-cell {
        color: #bbb;
      }

      &:not(.cell-selection) .datatable-body-row:hover {
        background: #32383e;

        .datatable-row-group {
          background: #32383e;
        }
      }
    }

    &.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
      color: #bbb;
    }
  }

  .ngxTableHeader {
    background-color: #242931;

    .header-buttons-left strong,
    .header-buttons {
      color: #bbb;

      .material-icons {
        color: #bbb;
      }

      .dropdown-menu li span {
        color: #000000;
      }
    }
  }

  .navbar-nav {
    .dropdown-menu {
      background-color: #1a202e;
      border: 1px solid #4e4e4e;

      .header {
        color: #bbb;
        border-bottom: 1px solid #232a31;
      }

      ul.menu {
        .menu-info .menu-title {
          color: #fff;
        }

        li a {
          border-bottom: 1px solid #232a31;

          &:hover {
            background-color: #1d2229;
          }
        }
      }
    }

    .user_dw_menu li {
      border-bottom: 1px solid #232a31;

      a {
        color: #bbb;
      }
    }
  }

  .fc-unthemed td {
    &.fc-today {
      background: transparent;
    }

    &.fc-day-top .fc-day-number {
      color: #ffffff;
    }
  }

  .fc-button-primary {
    background-color: #151414;
    border-color: #4a4a4a;
    color: #fff;
  }

  .fc-view>table {
    td {
      border-color: #65686d;
    }

    th {
      color: #fff;
      border-color: #65686d;
    }
  }

  .simple-line-icon-container>div {
    background: #10141d;
    border: 1px solid #1c212b;

    i {
      color: #96a2b4;
    }
  }

  /* Material Design Form style */

  .mat-checkbox-frame {
    border-color: #96a2b4;
  }

  .mat-radio-outer-circle {
    border-color: #96a2b4;
  }

  .mat-datepicker-content {
    .mat-calendar-next-button {
      color: #96a2b4;
    }

    .mat-calendar-previous-button {
      color: #96a2b4;
    }

    .time-container {
      background-color: #12161f;
    }

    .actions {
      background-color: #12161f;

      .mat-button-wrapper {
        .material-icons {
          color: white;
        }
      }

      .mat-stroked-button:not([disabled]) {
        border-color: rgba(255, 255, 255, 0.37);
      }
    }
  }

  .mat-datepicker-toggle {
    color: #96a2b4;
  }

  .mat-select-value {
    color: #96a2b4;
  }

  .mat-form-field-appearance-legacy {
    .mat-hint {
      color: #96a2b4;
    }

    .mat-form-field-label {
      color: #96a2b4;
    }

    .mat-form-field-underline {
      background-color: #96a2b4;
    }
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline-thick {
      color: #b7b7b7;
    }

    .mat-form-field-outline {
      color: #96a2b4;
    }

    &.mat-focused .mat-form-field-outline-thick {
      color: #bbb;
    }
  }

  .mat-stepper-horizontal {
    background-color: #1a202e;
  }

  .mat-stepper-vertical {
    background-color: #1a202e;
  }

  .mat-horizontal-stepper-header::after {
    border-top-color: rgba(255, 255, 255, 0.12);
  }

  .mat-horizontal-stepper-header::before {
    border-top-color: rgba(255, 255, 255, 0.12);
  }

  .mat-stepper-horizontal-line {
    border-top-color: rgba(255, 255, 255, 0.12);
  }

  .mat-form-field-ripple {
    background-color: #96a2b4;
  }

  .mat-select-arrow {
    color: #96a2b4;
  }

  input {
    caret-color: #96a2b4;
  }

  .mat-input-element {
    caret-color: #96a2b4;
  }

  .mat-hint {
    color: #96a2b4;
  }

  .mat-table {
    background: #1a202e;
  }

  .mat-cell {
    color: #96a2b4;
    border-bottom-color: rgba(234, 229, 229, 0.12);
  }

  .mat-footer-cell {
    color: #96a2b4;
  }

  .mat-header-cell {
    color: #96a2b4;
    border-bottom: 1px solid #66757d;
    border-top: 1px solid #66757d;
  }

  .mat-paginator {
    color: #96a2b4;
    background: #1a202e;
  }

  .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    color: #96a2b4;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: #bbb;
  }

  .mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: rgba(0, 0, 0, 0.25);
    }

    .mat-form-field-underline::before {
      background-color: #96a2b4;
    }
  }

  .mat-step-header .mat-step-label.mat-step-label-active {
    color: #96a2b4;
  }

  .mat-paginator-page-size .mat-select-trigger {
    color: #96a2b4;
  }

  .mat-dialog-container {
    background: #1a202e;
    color: #96a2b4;
  }

  .mat-expansion-panel {
    background: #101217;
    color: #96a2b4;
    border-bottom: 1px solid #31384b;
  }

  .mat-expansion-panel-header-title {
    color: #96a2b4;
  }

  .mat-expansion-panel-header-description {
    color: #96a2b4;
  }

  .mat-card {
    background: #12161f;
    color: #96a2b4;
  }

  .mat-card-subtitle {
    color: #96a2b4;
  }

  .mat-list-base .mat-list-option {
    color: #96a2b4;
  }

  .mat-list-base .mat-list-item {
    color: #96a2b4;
  }

  .list-group {
    a.list-group-item {
      color: #96a2b4;
    }

    button.list-group-item {
      color: #96a2b4;
    }

    .list-group-item:hover {
      background-color: rgba(10, 10, 10, 0.5);
    }
  }

  .mat-tab-label,
  .mat-tab-link {
    color: #96a2b4;
  }

  .materialTableHeader {
    background-color: #020910;

    h2 {
      color: #96a2b4;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #96a2b4;
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #96a2b4;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #96a2b4;
    }

    .header-buttons-left {
      input.search-field {
        background: #12161f;
        color: #96a2b4;
      }

      .search-icon {
        color: #96a2b4;
      }
    }
  }

  .mat-menu-panel {
    background: #12161f;
  }

  .mat-menu-item {
    color: #96a2b4;

    .mat-icon {
      color: #96a2b4;
    }
  }

  .mat-menu-item-submenu-trigger::after {
    color: #96a2b4;
  }

  .mat-datepicker-content {
    .mat-calendar {
      background: #12161f;
      color: #b8bbbd;

      .mat-calendar-body-cell-content {
        color: #b8bbbd;
      }

      .mat-calendar-table-header {
        color: #b8bbbd;
      }

      .mat-calendar-body-label {
        color: #b8bbbd;
      }

      .mat-calendar-body-today:not(.mat-calendar-body-selected) {
        border-color: rgba(255, 255, 255, 0.32);
      }

      .mat-calendar-arrow {
        border-top-color: #b8bbbd;
      }
    }
  }

  .mat-select-panel {
    background: #12161f;
    color: #b8bbbd;

    .mat-option {
      color: #b8bbbd;
    }

    .mat-option.mat-active {
      color: #b8bbbd;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .mat-pseudo-checkbox {
    color: #b8bbbd;
  }

  .mat-autocomplete-panel {
    background: #12161f;
    color: #b8bbbd;

    .mat-option-text {
      color: #b8bbbd;
    }
  }

  .container-login100 {
    background: #12161f;
  }

  .login100-form {
    background-color: #1a202e;
  }

  .login100-form-title,
  .error-header,
  .error-subheader {
    color: #ffffff;
  }

  .txt1 {
    color: #96a2b4;
  }

  .apexcharts-legend-text {
    color: #96a2b4 !important;
  }

  .mat-button[disabled],
  .mat-icon-button[disabled],
  .mat-stroked-button[disabled],
  .mat-flat-button[disabled],
  .mat-raised-button[disabled],
  .mat-fab[disabled],
  .mat-mini-fab[disabled],
  .mat-flat-button[disabled],
  .mat-raised-button[disabled],
  .mat-fab[disabled],
  .mat-mini-fab[disabled],
  .mat-flat-button[disabled],
  .mat-raised-button[disabled],
  .mat-fab[disabled],
  .mat-mini-fab[disabled] {
    color: rgba(255, 255, 255, 0.23);
  }

  .mat-stroked-button:not([disabled]) {
    border-color: rgba(255, 255, 255, 0.30);
  }

  .mat-bottom-sheet-container {
    background: #12161f;
  }

  .mat-row {
    border-bottom-color: rgba(255, 255, 255, 0.12);
  }
}

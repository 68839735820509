﻿/*
 *  Document   : _lightgallery.scss
 *  Author     : RedStar Template
 *  Description: This scss file for light gallery style classes
 */
.lg-outer {
  .lg-thumb-item,
  .lg-toogle-thumb {
    @include border-radius(0 !important);
  }
}
